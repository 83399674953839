/*!
Author: Weblozy
Author URI: https://www.weblozy.com
Version: 3.0
*/

import 'flowbite/dist/flowbite';

import "@lottiefiles/lottie-player";

import Alpine from 'alpinejs';

window.Alpine = Alpine;

import Splide from '@splidejs/splide';
window.Splide = Splide;

Splide.defaults = {
    perPage: 1,
    mediaQuery: 'min',
    autoplay: true,
    pauseOnFocus: false,
    pauseOnHover: false,
};

window.sliders = {
    home: {}
};

if (document.querySelector('.home-page .why-slider')) {
    sliders.home.why = new Splide('.why-slider', {
        type: 'loop',
        autoplay: true,
        gap: 20,
        fixedHeight: 316,
        pagination: true,
        arrows: false,
        breakpoints: {
            640: {
                perPage: 2
            },

            1200: {
                perPage: 3
            }
        }
    });

    sliders.home.why.mount();
}

if (document.querySelectorAll('.sidebar')) {
    let sidebars = document.querySelectorAll('.sidebar');

    sidebars.forEach(sidebar => {
        sidebar.querySelector('button.close').addEventListener('click', e => {
            e.target.closest('.sidebar').classList.remove('open');
        });
    });
}

if ( document.querySelector('.sidebar-overlay') ) {
    document.querySelector('.sidebar-overlay').addEventListener('click', e => {
        if (document.querySelector('.sidebar.open')) {
            document.querySelector('.sidebar.open').querySelector('button.close').click();
        }
    });
}

if (document.querySelector('[data-sidebar]')) {
    let items = document.querySelectorAll('[data-sidebar]');

    items.forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();

            let menu = e.target.closest('[data-sidebar]');
            let sidebar = `.sidebar.${menu.dataset.sidebar}`;
            document.querySelector(sidebar).classList.add('open');
        });
    })
}

if (document.querySelector('header ul.menu')) {
    let menu = document.querySelector('header ul.menu');
    let scontent = document.querySelector('.sidebar-menu .sidebar-content');
    scontent.innerHTML = menu.outerHTML;

    scontent.querySelector('.menu').className = 'mob-menu';

    let toggleElems = document.querySelectorAll('.mob-menu li a + .sub-menu');
    toggleElems.forEach(el => {

    });

    let toggles = document.querySelectorAll('.mob-menu .menu-item > a svg');
    toggles.forEach(btn => {
        btn.addEventListener('click', e => {
            e.preventDefault();

            e.target.closest('a').classList.toggle('active');
        });
    });

}

function stickyHeader() {
    let header = document.querySelector('header');

    const offset = header.offsetHeight;

    if (document.body.scrollTop > offset || document.documentElement.scrollTop > offset) {
        header.classList.add('is-sticky');
    }
    else {
        header.classList.remove('is-sticky');
    }
}

let scrolltop = document.querySelector('.scrolltop');

if (scrolltop) {
    scrolltop.addEventListener('click', () => {
        window.scrollTo(0,0);
    });
}

function scrollToTop() {
    const offset = 120;

    if (document.body.scrollTop > offset || document.documentElement.scrollTop > offset) {
        scrolltop.classList.add('active');
    }
    else {
        scrolltop.classList.remove('active');
    }
}

let lastPosY = window.scrollY;

window.addEventListener('scroll', (e) => {
    let curPosY = window.scrollY;
    let header = document.querySelector('header');

    if (curPosY >= lastPosY) {
        // Scrolling down
        header.classList.remove('is-sticky');
        header.classList.add('is-scrolling');


    }
    else {
        // Scrolling Up
        stickyHeader();

        if (curPosY == 0) {
            header.classList.remove('is-scrolling');
        }
    }

    lastPosY = curPosY;

    scrollToTop();
});

import { Tab } from 'bootstrap/dist/js/bootstrap.esm.min';
const triggerTabList = document.querySelectorAll('#myTab button')
triggerTabList.forEach(triggerEl => {
  const tabTrigger = new Tab(triggerEl)

  triggerEl.addEventListener('click', event => {
    event.preventDefault()
    tabTrigger.show()
  })
});

if (document.querySelector('.accordion')) {
    let accordionInit = () => {
        let aHead = document.querySelectorAll('.accordion-head');
        aHead.forEach(el => {
            el.addEventListener('click', (e) => {
                let state = false;

                state = (el.classList.contains('shown')) ? true : false;

                if (document.querySelector('.accordion-item.expanded')) {
                    document.querySelector('.accordion-item.expanded').classList.remove('expanded');
                }

                if (document.querySelector('.accordion-head.shown')) {
                    document.querySelector('.accordion-head.shown').classList.remove('shown');
                }

                if (el.classList.contains('shown')) {
                    el.classList.toggle('shown');
                    el.closest('.accordion-item').classList.remove('expanded');
                }

                if (!el.classList.contains('shown')) {
                    el.classList.toggle('shown');
                    el.closest('.accordion-item').classList.add('expanded');
                }

                if (state) {
                    el.classList.remove('shown');
                    el.closest('.accordion-item').classList.remove('expanded');
                }
            });
        });
    };

    accordionInit();
}

window.highlightLink = (el) => {
    console.log(el);
}

if (document.querySelector('.doc-container')) {
    const nodeInView = (node) => {
        const io = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if(entry.isIntersecting && entry.intersectionRatio > 0.05) {
                    let target = entry.target;
                    let id = target.id;

                    // console.log(target, id);

                    let sidebar = document.querySelector('.doc-sidebar');

                    if (sidebar.querySelector('a.active')) {
                        sidebar.querySelector('a.active').classList.remove('active');
                    }

                    let elem = sidebar.querySelector(`a[href="#${id}"]`);
                    // console.log(elem);
                    // debugger;
                    elem.classList.add('active');

                    // observer.disconnect();
                }
            });
        },{
            root: null,
            rootMargin: '100px',
            threshold: 1,
        });

        io.observe(node);
    };

    let titles = document.querySelectorAll('.doc-wrapper h2[id]');
    titles.forEach(nodeInView);

    document.querySelectorAll('.doc-container .request-table td, .doc-container .request-table th').forEach(item => {
        item.innerHTML = `<div>${item.innerHTML}</div>`;
    });
}

if (document.querySelector('#feature-toggle')) {
    let toggle = document.querySelector('#feature-toggle');
    let currentTab = document.querySelector('#current-tab');
    let upcomingTab = document.querySelector('#upcoming-tab');

    toggle.addEventListener('change', (e) => {
        if (e.target.checked) {
            upcomingTab.click();
        }
        else {
            currentTab.click();
        }
    });
}

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;

if (document.querySelector('.tl-container')) {
    let container = document.querySelector('.tl-container');
    let wrapper = container.querySelector('.tl-wrapper');
    let items = wrapper.querySelectorAll('.tl-item');

    let itemFirst = items[0];
    let itemLast = items[items.length - 1];

    let tlHeight = (itemFirst.offsetHeight + itemLast.offsetHeight) / 2;

    container.style.setProperty('--scard-h', tlHeight + 'px');

    ScrollTrigger.create({
        trigger: wrapper,
        start: 'top 50%',
        end: 'bottom bottom',
        onUpdate: self => {
            let progress = self.progress.toFixed(3);
            container.style.setProperty('--progress', progress);
        }
    });
}

if (document.querySelector('[data-setbg]')) {
    let elems = document.querySelectorAll('[data-setbg]');
    elems.forEach(elem => {
        elem.style.backgroundImage = `url(${elem.dataset.setbg})`;
    });
}

import AOS from 'aos';
import 'aos/dist/aos.css';
window.AOS = AOS;

AOS.init({
    once: true,
    duration: 700,
});

Alpine.start();
